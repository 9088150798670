
import { Box, Divider, Drawer, List, ListItem, ListItemIcon, Toolbar } from "@mui/material";
import React, { useState } from "react";
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AIResultIcon from '@mui/icons-material/Psychology';
import SolrIcon from '@mui/icons-material/WbSunny';
import PeopleIcon from '@mui/icons-material/People';
import DocumentIcon from '@mui/icons-material/DocumentScanner'
import DocumentCaseLawIcon from '@mui/icons-material/AccountBalanceOutlined'
import { iconStyle } from "../Shared/MaterialTableShared";
import { Outlet, useNavigate } from "react-router-dom";


const AdminContainer = (props: any) => {

	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const navigate = useNavigate();
	const [open, setOpen] = useState<false | true>(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const drawer = (
		<div>

			<List >
				<ListItem button key={'dashboard'}
					onClick={(): void => {
						navigate('/admin/dashboard')
					}}
				>
					<ListItemIcon>
						<DashboardIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={'Dashboard'} />
				</ListItem>

				<ListItem button key={'users'}
					onClick={(): void => {
						navigate('/admin/users')
					}}
				>
					<ListItemIcon>
						<PeopleIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={'User Management'} />
				</ListItem>

				<ListItem button key={'msha-documents'}
					onClick={(): void => {
						navigate('/admin/msha-documents')
					}}
				>
					<ListItemIcon>
						<DocumentIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={'MSHA Documents'} />
				</ListItem>

				<ListItem button key={'case-law'}
					onClick={(): void => {
						navigate('/admin/case-law')
					}}
				>
					<ListItemIcon>
						<DocumentCaseLawIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={'Case Law'} />
				</ListItem>

				<ListItem button key={'document-management'}
					onClick={(): void => {
						navigate('/admin/document-management')
					}}
				>
					<ListItemIcon>
						<DocumentCaseLawIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={'Document Management'} />
				</ListItem>

				<ListItem button key={'ai-results'}
					onClick={(): void => {
						navigate('/admin/ai-results')
					}}
				>
					<ListItemIcon>
						<AIResultIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={'AI Results'} />
				</ListItem>
				<a style={{textDecoration: 'none', color: 'inherit'}} target="_blank" href={process.env.REACT_APP_SOLR_ADMIN_URL}>
				<ListItem button key={'solr-dashbaord'}

				>
					<ListItemIcon>
						<SolrIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={'Solr Dashboard'} />
				</ListItem>
				</a>
			</List>
		</div>

	);

	function terms() {
		props.history.push("/termsAndConditions");
	}

	const menuId = "primary-search-account-menu";

	const container = window !== undefined ? () => window().document.body : undefined;

	return (

        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ width: '250px', backgroundColor: '#ddd' }}>
                {drawer}
            </div>
            <div style={{ flexGrow: 1, backgroundColor: '#eee' }}>
			<Outlet />
            </div>
        </div>

		// <div>
		// 	<Box>
		// 		<Drawer
		// 			PaperProps={{ sx: { backgroundColor: COLORS.BRAND_PRIMARY_DARK, color: COLORS.WHITE_PAPER } }}
		// 			container={container}
		// 			variant="temporary"
		// 			open={mobileOpen}
		// 			onClose={handleDrawerToggle}
		// 			ModalProps={{
		// 				keepMounted: true, // Better open performance on mobile.
		// 			}}
		// 			sx={{
		// 				display: { xs: 'block', sm: 'none' },
		// 				'& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
		// 			}}
		// 		>
		// 			{drawer}
		// 		</Drawer>
		// 		<Drawer
		// 			PaperProps={{ sx: { backgroundColor: COLORS.BRAND_PRIMARY_DARK, color: COLORS.WHITE_PAPER } }}
		// 			variant="permanent"
		// 			sx={{
		// 				display: { xs: 'none', sm: 'block' },
		// 				'& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
		// 			}}
		// 			open
		// 		>
		// 			{drawer}
		// 		</Drawer>
		// 	</Box>
		// 	<div style={{ marginLeft: 250 }}>
		// 		<Outlet />
		// 	</div>

		// </div>
	);
};

export default AdminContainer;
